.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  justify-content: center;

  &.center {
    align-items: center;
  }

  &.dropdownGroup {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;

    button {
      justify-content: flex-start;
      width: 100%;
      border-radius: 0;
      padding: 1.6rem;
    }
  }

  &.spaceBetween {
    justify-content: space-between;

    & > * {
      flex: 1;
    }
  }

  @media (--viewport-m) {
    align-items: center;
    flex-direction: row;
    gap: 1.6rem;

    &.dropdownGroup {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
